import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PaddedContent from "../components/paddedContent"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="9w-content-not-found"
      style={{
        padding: "200px 0",
        backgroundColor: "#041E42",
        color: "white",
      }}
    >
      <PaddedContent>
        <h1>NOT FOUND</h1>
        <p style={{ marginBottom: "20px" }}>
          You just hit a route that doesn&#39;t exist.
        </p>
        <Link to="/" style={{ textDecoration: "underline" }}>
          Go to Home
        </Link>
      </PaddedContent>
    </div>
  </Layout>
)

export default NotFoundPage
